import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import countryList from 'react-select-country-list';

import { Layout, SEO } from '../components';
import { Link, Redirect } from 'gatsby';
import { logout, login } from '@src/utils/mmc-api/auth';
import * as api from '@src/utils/mmc-api/api';
import Documents from '../components/Documents';
import Bookings from '../components/Bookings';
import Profile from '../components/Profile';
import Spinner from '../components/UI/Spinner';
import calendarIcon from '../assets/icons/calendar_month_black_24dp.svg';
import accountIcon from '../assets/icons/manage_accounts_black_24dp.svg';
import documentIcon from '../assets/icons/picture_as_pdf_black_24dp.svg';
import logoutIcon from '../assets/icons/logout.svg';


const profile = () => {
    const user = useSelector((state) => state.user.data);
    const userIsLoading = useSelector((state) => state.user.isLoading);
    const [section, setSection] = useState('documents');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [render, setRender] = useState(true)

    useEffect(() => {
        if (userIsLoading) return;
        if (!user?.email) {
            window.location.href = "/";
        }
    }, [user, userIsLoading]);

    async function fetchOwnedProducts() {
        if (user && user?.email) {
            const owned = await api.ownedProducts();
            setProducts(owned);
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchOwnedProducts();
    }, [user]);

    const rerenderHandler = () => {
        setRender(!render);
    }

    return (
        <Layout>
            <SEO title="Profile!" />
            {loading ? (
                <div className="container">
                    <Spinner />
                </div>
            ) : (
                <div className="container">
                    <div className="wrapper">
                        <div className="sideBar">
                            <div className={section === 'documents' ? "sideBarButtonContainer selected" : "sideBarButtonContainer"}>
                                <button
                                    onClick={() => {
                                        setSection('documents');
                                    }}
                                >
                                    <div className='itemContainer'><img src={documentIcon} width="30" height="30" /><span className='no-view itemText'>Purchased Documents</span></div>

                                </button>
                            </div>
                            <div className={section === 'bookings' ? "sideBarButtonContainer selected" : "sideBarButtonContainer"}>
                                <button
                                    onClick={() => {
                                        setSection('bookings');
                                    }}
                                >
                                    <div className='itemContainer'><img src={calendarIcon} width="30" height="30" /><span className='no-view itemText'>Tutorial Bookings</span></div>

                                </button>
                            </div>
                            <div className={section === 'profile' ? "sideBarButtonContainer selected" : "sideBarButtonContainer"}>
                                <button
                                    onClick={() => {
                                        setSection('profile');
                                    }}
                                >
                                    <div className='itemContainer'><img src={accountIcon} width="30" height="30" /><span className='no-view itemText'>Profile Details</span></div>
                                </button>
                            </div>
                            <div className="sideBarButtonContainer">
                                <button
                                    onClick={() => {
                                        logout('/');
                                    }}
                                >
                                    <div className='itemContainer'><img src={logoutIcon} width="30" height="30" /><span className='no-view itemText'>Log Out</span></div>
                                </button>
                            </div>


                        </div>
                        <div className="mainContent">
                            {section === 'documents' && <Documents documents={products} user={user} rerender={rerenderHandler} />}
                            {section === 'bookings' && <Bookings />}
                            {section === 'profile' && <Profile user={user} />}

                        </div>
                    </div>

                    {/* <div id="libra">
          <Link to="/library" className="library">
            Your library
          </Link>
        </div>
        <div>
          <select className="mr">
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Ms">Ms</option>
            <option value="Prof">Prof</option>
          </select>
          <br />
        </div>
        <p id="p">First Name</p>
        <div className="input">
          <input name="firstName" />
        </div>
        <p id="p1">Last Name</p>
        <div className="input1">
          <input name="lastName" />
        </div>
        <br />
        <div>
          <select id="studentParent">
            <option value="Student">Student</option>
            <option value="Parent">Parent</option>
            <option value="Student">Teacher</option>
          </select>
        </div>
        <div>
          <p id="coun">Country Select</p>
        </div>
        <div id="country">
          <Select options={options} value={value} onChange={changeHandler} />
        </div>
        <br />
        <p id="p2">Education Facility</p>
        <div id="educ">
          <label>
            <input EducationFacility="EducationFacility" />
          </label>
        </div>
        <div id="btnval">
          <button type="submit">Validate</button>
        </div>
        <button onClick={logout}>Logout</button> */}
                </div>
            )}
        </Layout>
    );
};

export default profile;
